<template>
  <vue-cal
    hide-view-selector
    small
    :time="false"
    :disable-views="['years', 'year', 'month', 'week', 'day']"
    default-view="month"
    eventsOnMonthView
    :dblclickToNavigate="false"
    :events="formattedEvents"
    @view-change="fetchCalendarEvents"
  >
  </vue-cal>
</template>

<script>
import VueCal from "vue-cal";
//import "vue-cal/dist/vuecal.css";

export default {
  name: "dashboard-calendar",
  components: { 'vue-cal': VueCal },
  props: {
    events: { type: Array, required: true }
  },
  computed: {
    formattedEvents () {
      let parsedEvents = {};
      let parsedEventsList = [];

      this.events.forEach(event => {
        let _date = new Date(event.eventDate).toLocaleDateString();

        if (parsedEvents[_date + event.type]) {
          parsedEvents[_date + event.type].title = parseInt(parsedEvents[_date + event.type].title) + 1;
          return;
        }

        parsedEvents[_date + event.type] = { start: _date, end: _date, class: this.getCssClass(event.type), title: '1' };
      })

      for (let event in parsedEvents) {
        parsedEventsList.push(parsedEvents[event]);
      }

      return parsedEventsList;
    },
  },
  methods: {
    fetchCalendarEvents({ startDate, endDate }) {
      this.$emit('fetch-events', { startDate, endDate });
    },
    getCssClass (eventType) {
      return eventType.toLowerCase().replace(' ', '-');
    }
  }
};
</script>
